import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBtn,{attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.isDialogVisible = true}}},[_vm._v(" Open Dialog ")]),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Dialog ")]),_c(VCardText,[_vm._v(" Biscuit fruitcake marshmallow jelly beans jujubes halvah cupcake topping. Chocolate cookie jelly-o toffee tart oat cake. Tart sugar plum gingerbread halvah muffin sweet. Cake halvah tart soufflé pudding. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","dark":"","outlined":""},on:{"click":function($event){_vm.isDialogVisible = false}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.isDialogTwoShow = !_vm.isDialogTwoShow}}},[_vm._v(" Open Dialog 2 ")])],1)],1)],1),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.isDialogTwoShow),callback:function ($$v) {_vm.isDialogTwoShow=$$v},expression:"isDialogTwoShow"}},[_c(VCard,[_c(VCardTitle,[_c('span',[_vm._v("Dialog 2")])]),_c(VCardText,[_vm._v("I'm a nested dialog.")]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.isDialogTwoShow = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }