import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Open Dialog ")])]}}]),model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Use Google's location service? ")]),_c(VCardText,[_vm._v("Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.isDialogVisible = false}}},[_vm._v(" Disagree ")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){_vm.isDialogVisible = false}}},[_vm._v(" Agree ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }